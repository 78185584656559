exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-zabiegi-js": () => import("./../../../src/pages/zabiegi.js" /* webpackChunkName: "component---src-pages-zabiegi-js" */),
  "component---src-pages-zespol-js": () => import("./../../../src/pages/zespol.js" /* webpackChunkName: "component---src-pages-zespol-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-article-list-js": () => import("./../../../src/templates/articleList.js" /* webpackChunkName: "component---src-templates-article-list-js" */),
  "component---src-templates-clinic-js": () => import("./../../../src/templates/clinic.js" /* webpackChunkName: "component---src-templates-clinic-js" */),
  "component---src-templates-employee-js": () => import("./../../../src/templates/employee.js" /* webpackChunkName: "component---src-templates-employee-js" */),
  "component---src-templates-surgery-js": () => import("./../../../src/templates/surgery.js" /* webpackChunkName: "component---src-templates-surgery-js" */)
}

